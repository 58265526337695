import {getPaginatedData} from "./commonService";

export default class AddressService {

    async getAllCountries() {
        return await getPaginatedData("/api/Country", "Ülkeler");
    }

    async getCities(countryId) {
        return await getPaginatedData("/api/City/query?countryId=" + countryId, "Şehirler");
    }

    async getTowns(cityId) {
        return await getPaginatedData("/api/Town/query?cityId=" + cityId, "İlçeler");
    }

}